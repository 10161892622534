import React from "react";
import { UnilevelComissionCss } from "./RewardStyle";

const UnilevelComission = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <UnilevelComissionCss>
      <figure className="manual-background">
        <img src="images/unilevel-commission-bg_new.jpg" alt="Banner background" />
      </figure>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="unilevel-commission-content">
              <h2>
                <span>UNILEVEL COMMISSIONS</span>
              </h2>
              <ul>
                <li>Earn from Downline Sales</li>
                <li>Infinite Width, 5 Levels Deep</li>
                <li>Rank-Based Earnings</li>
                <li>Equal Opportunity</li>
                <li>Passive Income</li>
                <li>Build a Network</li>
                <li>Flexible Earnings</li>
                <li>Long-Term Income</li>
                <li>Reward for Leadership</li>
              </ul>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf" 
                  href="https://admin.shopkaire.com/media/pdf/Kaire_rewards_plan.pdf"
                  target="_blank">
                  The full rewards plan
                </a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>
                  Signup
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </UnilevelComissionCss>
  );
};

export default UnilevelComission;
