import React from "react";
import { SmartshipBonusCss } from "./RewardStyle";

const SmartshipBonus = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <SmartshipBonusCss>
      <figure className="manual-background">
        <img src="images/smartship-bonus-bg.png" alt="Banner background" />
      </figure>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="smartship-bonus-content">
              <h2>
                <span>SMARTSHIP CUSTOMER BONUS</span>
              </h2>
              <ul>
                <li>
                  Earn 10% Kaire cash on all personal customer orders.
                </li>
                <li>
                  Rewards for loyalty, even if they're not on smartship.
                </li>
                <li>
                  Extra benefits beyond the two ShareKaire bonus orders.
                </li>
                <li>
                  Shop, save, and earn with Smartship Customer Bonus!
                </li>
                <li>
                  Get Kaire products delivered, as a Smartship, Every 30 days at a 10% discount.
                </li>
              </ul>
              <div className="btn-wrap">
                <a type="button" className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf" 
                  href="https://admin.shopkaire.com/media/pdf/Kaire_rewards_plan.pdf"
                  target="_blank">The full rewards plan</a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>Signup</button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </SmartshipBonusCss>
  );
};

export default SmartshipBonus;
