import React from "react";
import { HighlightSectionCss } from "./RewardStyle";

const HighlightSection = (props) => {
  const { Validatelogin, userRank } = props
  return (
    <HighlightSectionCss>
      <figure className="manual-background">
        <img src="images/highlight-bg.png" alt="Banner background" />
      </figure>
      <div className="container">
        <div className="cstm-row">
          <div className="col-6">
            <div className="highlight-text">
              <h2>
                <span>COMPANY HIGHLIGHTS</span>
              </h2>
              <ul>
                <li>Quality Products for over 30+ Years</li>
                <li>Consumable Products.</li>
                <li>Multiple Income Opportunities</li>
                <li>No Demotions – Remain at the level you earned for life.</li>
                <li>
                  No Pass ups – If someone you sponsor excels in sales, you will
                  be rewarded and not passed up.
                </li>
                <li>
                  Get Kaire products delivered, as a Smartship, Every 30 days at a 10% discount - cancel anytime.
                </li>
              </ul>
              <div className="btn-wrap">
                <a
                  type="button"
                  className="btn"
                  // href="https://commandconcept.csdevhub.com/media/pdf_files/Rewards_Plan_040324.pdf"
                  href="https://admin.shopkaire.com/media/pdf/Kaire_rewards_plan.pdf"
                  target="_blank"
                >
                  The full rewards plan
                </a>
                {userRank?.user_data?.userdetails?.[0]?.qualified_as_rank !== 3 && <button type="button" className="btn btn-2 " onClick={() => Validatelogin()}>
                  Signup
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </HighlightSectionCss>
  );
};

export default HighlightSection;
